<template>
	<div class="page-live-list" v-if="!show.isMap">
		<div class="el-content mb12">
			<a-dropdown>
			    <template #overlay>
			        <a-menu>
						<a-menu-item @click="changeLiveStatus(1)">上架所有监控</a-menu-item>
						<a-menu-item @click="changeLiveStatus(0)">下架所有监控</a-menu-item>
			        </a-menu>
			    </template>
			    <a-button class="cll-more">
			        更多操作
			        <i class="ri-arrow-down-s-line"></i>
			    </a-button>
			</a-dropdown>
			<a-tooltip title="切换地图展示">
				<div class="change-pacel" @click="show.isMap = true"><i class="ri-road-map-line ri-top ri-xl"></i></div>
			</a-tooltip>
			<div class="kd-pacel">搜索参数</div>
			<div class="mb12">
				<span class="f12">监控设备：</span>
				<a-input style="width:400px" v-model:value="search.live_uid" placeholder="监控设备UID" />
			</div>
			<div class="mb12">
				<span class="f12">监控标题：</span>
				<a-input style="width:400px" v-model:value="search.title" placeholder="监控标题" />
			</div>
			<div class="mb12">
				<span class="f12">监控状态：</span>
				<a-select style="width:400px;margin-right:6px;" v-model:value="search.open_status">
					<a-select-option value="">全部状态</a-select-option>
					<a-select-option :value="1" >上架中</a-select-option>
					<a-select-option :value="2" >已下架</a-select-option>
				</a-select>
			</div>
			<div class="mb12">
				<span class="f12">监控分类：</span>
				<a-select  style="width:400px;margin-right:6px;" v-model:value="search.category_id">
					<a-select-option value="">全部分类</a-select-option>
					<a-select-option v-for="(val,ind) in typeData" :key="ind" :value="val.id" >{{ val.name }}</a-select-option>
				</a-select>
			</div>
			<a-space style="margin-left: 60px;">
				<a-button @click="resetSearch">重置</a-button>
				<a-button type="primary" @click="getLiveList(1,info.limit)"> <i class="ri-search-2-line ri-top"></i> 搜索</a-button>
				<router-link :to="{path:isShop == 0 ?'/live/liveEdit':'/many_store/live/liveEdit'}">
					<a-button type="primary" v-has="{action:'/live/liveEdit',plat:isShop}">
						<i class="ri-add-line ri-lg ri-top"></i>
						新增监控
					</a-button>
				</router-link>
			</a-space>
		</div>
		<div class="el-content">
			<a-table size="small" :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'标题',dataIndex:'title'},
				{title:'UID',dataIndex:'live_uid'},
				{title:'位置',dataIndex:'address'},
				{title:'状态',dataIndex:'open_status',slots:{customRender:'open_status'}},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'排序',dataIndex:'rank'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #open_status="{record}">
					<a-tag :color="record.open_status == 1 ?'#00CC66':'#999'">{{ record.open_status==1 ?'已上架':'已下架'}}</a-tag>
				</template>
				<template #action={record}>
					<a-space>
						<router-link :to="{path:isShop ==0 ?'/live/liveEdit':'/many_store/live/liveEdit',query:{id:record.id}}">
							<kd-button type="primary" 
								title="编辑" 
								icon="ri-edit-line" 
								v-has="{action:'/live/liveEdit',plat:isShop}">
							</kd-button>
						</router-link>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" @click="liveDel(record.id)" v-has="{action:'wxapp_live_del',plat:isShop}"></kd-button>
						<kd-button type="purple" title="预览" icon="ri-slideshow-4-line" @click="livePreview(record)" v-has="{action:'wxapp_live_del',plat:isShop}"></kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
				    show-size-changer
				    :default-current="info.page"
				    :total="info.count"
					:defaultPageSize="info.limit"
				    @showSizeChange="(p,e)=>{getLiveList(info.page,e)}"
					@change="(e)=>{getLiveList(e,info.limit)}"
				/>
			</div>
		</div>
		
		<a-modal v-model:visible="show.play" title="监控预览" :footer="null" @cancel="clearPlay" width="700px">
			<div id="previewPlayer"></div>
		</a-modal>
	</div>
	<page-live-map @changePacel="show.isMap = false" ref="live" :is-shop="0" v-else></page-live-map>
</template>
<script>
import { onActivated, reactive, toRefs } from 'vue'
import HlsJsPlayer from 'xgplayer-hls.js'
import liveModel from "@/api/live.js"
import common from "@/api/common.js"
import store from '@/store'
import PageLiveMap from '@/components/layout/live/page-live-map.vue'
export default{
	name:'pageLiveList',
	components:{
		PageLiveMap
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{
				live_uid:"",
				title:"",
				open_status:"",
				category_id:""
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{
				play:false,
				isMap:false
			},
			typeData:[],	//监控分类
			player:null,	//预览监控信息
			
		})

		onActivated(()=>{
			if(!store.state.isRefreshPage ){
				getLiveList(_d.info.page,_d.info.limit)
			}
		})

		getLiveList(1,_d.info.limit)
		liveModel.getLiveTypeList(1,999).then(res=>_d.typeData = res.list)
		
		function getLiveList(page,limit){
			liveModel.getLiveList(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		function resetSearch(){
			_d.search = {
				uid:"",
				title:"",
				open_status:"",
				category_id:""
			}
		}
		function livePreview(data){
			if( data.m3u8_src ){
				_d.show.play = true
				let timer = setTimeout(function(){
					clearTimeout(timer)
					_d.player = new HlsJsPlayer({
						id: 'previewPlayer',
						url:data.m3u8_src,
						autoplay:true,
						fluid: true,
						muted:true,
						screenShot:true,
						lang:'zh-cn',
						error:"监控不在线",
						useHls: true
					});
				},1000)
			}
		}

		function clearPlay(){
			_d.player.destroy(true)
			_d.player.once('destroy',()=>{
				_d.player = null
			})
		}

		const liveDel = (id)=>common.deleteDataList(id,11,"确认删除该监控吗?").then(()=>{
			getLiveList(_d.info.page,_d.info.limit)
		})

		const changeLiveStatus = (status)=>liveModel.changeLivePutaway(status,()=>getLiveList(_d.info.page,_d.info.limit))

		

		return{
			...toRefs(_d),
			getLiveList,
			liveDel,
			changeLiveStatus,
			resetSearch,
			livePreview,
			clearPlay
		}
	}
}
</script>
<style lang="scss">
	.page-live-list{
		.cll-more{
			position: absolute;
			right: 54px;
		}

		.change-pacel{
			position: absolute;
			right: 12px;
			width: 32px;
			height: 32px;
			background: rgb(34, 181, 240);
			color: #fff;
			text-align: center;
			line-height: 36px;
			border-radius: 50%;
			cursor: pointer;
		}
	}
</style>